module.exports = {
  getUrlWithLocale(url, locale) {
    if (!locale) {
      return url;
    }

    return url && url.split("?").length > 1
      ? `${url}&lang=${locale}`
      : `${url}?lang=${locale}`;
  },
  getLocaleFromReq(req) {
    const referer = req.headers.referer;
    const isEnglish = referer && referer.split("/")[3] === "en";

    return isEnglish ? "en" : "et";
  },
};
