import React, { useContext, useEffect, useState, useRef } from "react";
import Link from "next/link";
import styled from "styled-components";
import { useIsVisible } from "react-is-visible";
import { Spin } from "antd";
import * as NextImage from 'next/image';

import translations from "../../config/translations";
import LanguageContext from "../../contexts/Language";

import { KeyboardArrowRight } from "@styled-icons/material";
import { MAIN_COLOR } from "../../constants";

import { addToCart } from "../../store/cart/actions";
import RoundedButton from "../rounded-button";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRedux } from "../../lib/redux";

import { getProductPrice } from "./utils";

import {
  getProductPriceData,
} from "../../helpers/get-produduct-price-class";
import CustomLink from "../custom-link";

const ProductCardWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  cursor: pointer;
`;

const ProductImageWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ProductImage = styled(NextImage.default)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: auto;
  max-width: 100%;
  height: auto;
  position: absolute;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease all;

  ${ProductCardWrapper}:hover & {
    transform: scale(1.1);
  }

	max-width: 100% !important;
	min-width: 0 !important;
	width: auto !important;
  object-fit: contain;
`;

const ProductContent = styled.div`
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductTitle = styled.a`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25em;
  width: 100%;
  text-align: center;
  color: inherit;
`;

const ProductReadMore = styled(Link)`
  color: #000;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ArrowRight = styled(KeyboardArrowRight)`
  width: 20px;
  color: ${MAIN_COLOR};
  margin-right: 10px;
  margin-left: -5px;
  display: inline;
`;

const ProductInfo = styled.div`
  width: 75%;
  margin: 20px auto 0 auto;
  text-align: center;
`;

const Price = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
`;

const getCartItem = (productData) => {
  const activeVariation =
    (productData.variations && productData.variations[0]) || {};

  return {
    id: `${productData.id}_${activeVariation.color}`,
    title: productData.name,
    thumbnail:
      activeVariation.image ||
      "https://ae01.alicdn.com/kf/HTB1EEeAKf5TBuNjSspmq6yDRVXac.jpg",
    quantity: 100,
    numberOfColors: 1,
  };
};

function ProductCard({ product, addToCart }) {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const lang = useContext(LanguageContext);
  const [background, setBackground] = useState("");
  const [priceClass, setPriceClass] = useState(0);
  const [priceMultiplier, setPriceMultiplier] = useState(0);
  const [productPrice, setProductPrice] = useState(0);

  useEffect(() => {
    if (isVisible) {
      let imageUrl = product.variations && product.variations[0]?.image;

      if (!imageUrl) {
        // TODO: FIX IT PROPERLY! Frontend hotfix atm.
        imageUrl = product.variations && product.variations[0]?.all_images?.split(',')[0]
      }

      if (!imageUrl) {
        return;
      }

      const downloadingImage = new Image();
      let fullUrl = imageUrl;
      if (['anda', 'mob', 'stricker', 'pf', 'xindao'].includes(product.source)) {
        const url = new URL(imageUrl);
        const pathname = url.pathname;
        const imageFileName = pathname.split('/').pop();
        const s3Path = `images/${product.source}/${imageFileName}`;
        fullUrl = `https://kaubad-integration-files.s3.eu-west-1.amazonaws.com/${s3Path}`;
      }

      downloadingImage.onload = function () {
        setBackground(fullUrl);
      };

      downloadingImage.onerror = function () {
        // Set background to the original imageUrl
        setBackground(imageUrl);
      };

      downloadingImage.src = fullUrl;
    }
  }, [isVisible]);

  useEffect(() => {
    const { priceClass, multiplier } = getProductPriceData(product);

    setPriceClass(priceClass);
    setPriceMultiplier(multiplier);
  }, []);

  useEffect(() => {
    setProductPrice(getProductPrice({
      smallestPrice: priceClass,
      priceMultiplier,
      priceOnRequestString: translations["Price on request"][lang],
      isManual: product.manual_product,
    }))
  }, [priceClass, priceMultiplier])

  return (
    <ProductCardWrapper>
      <CustomLink
        href={`/products/[slug]`}
        as={`/products/${product.slug}`}
        locale={lang}
        passHref
      >
        <a>
          <ProductImageWrapper ref={nodeRef}>
            {background ? (
              <ProductImage src={background} width="300" height="300" />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  position: "absolute",
                  width: "100%",
                }}
              >
                <Spin />
              </div>
            )}
          </ProductImageWrapper>
        </a>
      </CustomLink>

      <ProductContent>
        <CustomLink
          href={`/products/[slug]`}
          as={`/products/${product.slug}`}
          passHref
        >
          <ProductTitle>{product.name}</ProductTitle>
        </CustomLink>
        <ProductInfo>
          <RoundedButton
            bgColor="#4B5068"
            small
            click={() => addToCart(getCartItem(product))}
          >
            {translations["Add to query"][lang]}
          </RoundedButton>
        </ProductInfo>
        <Price>
          {`${translations["Price class"][lang]} `}
          {productPrice}{" "}
          €
        </Price>
      </ProductContent>
    </ProductCardWrapper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: bindActionCreators(addToCart, dispatch),
  };
};

export default withRedux(connect(null, mapDispatchToProps)(ProductCard));
